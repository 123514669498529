const pathStartsWithBy = /^\/(by|cy|cy_en)(\/|$)/

export const replaceWithAbsolutePathForBy = (to) => {
  var redirectPath = window?.location.origin
  if (typeof to === "string" && pathStartsWithBy.test(to)) {
    return `${redirectPath}${to}`
  }

  return to
}
