import React, {useState, useRef, useCallback, useEffect } from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import Start from "src/components/Start/Start"
import Top from "src/components/Top/Top"
import Top2 from "src/components/Top2/Top2"

import Description from "src/components/companies/Description/Description"
import HowItWorks from "src/components/companies/HowItWorks/HowItWorks"
import Benefits from "src/components/companies/Benefits/Benefits"

import COUNTRY from "../../constants"
import {graphql, navigate, useStaticQuery} from "gatsby"
import {useTranslation} from "react-i18next"
import { navigateToTheNewWebsite } from "../../utils/navigate/navigateToTheNewWebsite"

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query mainPageClientsEuEn {
      site {
        siteMetadata {
          geoIpPath
          senderPath
          countries {
            en {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  const platinum_amount = 0
  const platinum_activities_amount = 0

  const { t,i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.eu_en)
  }, [i18n])

  const [email, setEmail] = useState("")
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [form, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then(() => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const transferEmail = (email) => {
    setForm(Object.assign(form, {email}))
    setEmailDisabled(true)
  }
  const startRef = useRef()

  const geoIpPath = data.site.siteMetadata.geoIpPath
  const noCountryFound = t('geo.no_country_detected')

  const geoIpLookup = () => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(geoIpPath, {
          method: 'GET',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        })
        if (!response.ok) {
          reject(noCountryFound)
        }
        const data = await response.json()
        resolve(data.country)
      }
      catch (ex) {
        reject(noCountryFound)
      }
    })
    promise.then((switchedCountry) => {
      if (!switchedCountry) {
        return navigateToTheNewWebsite(`/cy_en/`)
      }
      let countryLowerCase = String(switchedCountry).toLowerCase()
      if (countryLowerCase === 'lt') {
        countryLowerCase = 'lt_lt'
      }
      if (countryLowerCase === 'cy') {
        countryLowerCase = 'cy_en'
      }
      if (['lt_lt', 'lt_en', 'cy_en'].indexOf(countryLowerCase) === -1) {
        return navigateToTheNewWebsite(`/cy_en/`)
      }
      return navigateToTheNewWebsite(`/${countryLowerCase}/`)
    }).catch((s) => console.warn(s))
  }

  const memoGeoIpLookup = useCallback(geoIpLookup,
    [geoIpPath, noCountryFound])

  // check if route not through local route so it get the normal route country
  useEffect(() => {
    return setTimeout(memoGeoIpLookup, 1000)
  }, [memoGeoIpLookup])

  return (
    <div className="main-page">
      <Head
        lang="cy_en"
        title={t('head.index.title')}
        description={t('head.description')}
        slug="/"
      />
      <Layout
        location={location}
        hotLine={data.site.siteMetadata.countries.en.hotLine}
        country="cy_en"
      >
        <Top2
          type="clients"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount=""
          platinum_activities_amount=""
          country="cy_en"
        />
        <Top
          type="companies"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={platinum_amount}
          country="cy_en"
        />
        <Description
          platinum_amount={platinum_amount}
          platinum_activities_amount={platinum_activities_amount}
        />
        <HowItWorks />
        <Benefits />
        <Start
          type="companies"
          startRef={startRef}
          email={email}
          emailDisabled={emailDisabled}
          setEmail={setEmail}
          setForm={sendForm}
        />
      </Layout>
    </div>
  )
}

export default ClientsMain
